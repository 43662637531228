body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg {
  animation: slide 15s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #ff5300 50%, #ff8000 50%);
  background-image: linear-gradient(-60deg, #051055 50%, #0077cc 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

@media (prefers-color-scheme: dark) {
  .bg {
    background-image: linear-gradient(-60deg, #051055 50%, #0077cc 50%);
  }
}
.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 20s;
}

.bg3 {
  animation-duration: 25s;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

@keyframes slide2 {
  0% {
    transform: rotate(0deg) translateX(-25%) translateY(-25%);
  }
  100% {
    transform: translateX(25%) rotate(180deg) translateY(-25%);
  }
}
